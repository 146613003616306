
const breakpoints = ['xs','sm','md','lg']

export function isBreakpoint( alias ) {
    return $('.device-' + alias).is(':visible');
}

export function isBreakpointMin( alias ) {
  let found = false;
  const minBreakpoints = breakpoints.filter(x => {
    if(found){
      return true;
    }
    if(x == alias) {
      found = true;
      return true;
    }
    return false;
  });
  minBreakpoints.filter(alias2 => $('.device-' + alias2).is(':visible')).length > 0;
}

export function isBreakpointMax( alias ) {
  let found = false;
  const minBreakpoints = breakpoints.filter(x => {
    if(found){
      return true;
    }
    if(x == alias) {
      found = true;
      return true;
    }
    return false;
  });
  minBreakpoints.filter(alias2 => $('.device-' + alias2).is(':visible')).length == 0;
}

