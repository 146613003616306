import React, { useEffect, useState } from 'react'
import WarehouseLayout from '../WarehouseLayout'
import DataGridTableToolbar from "@/utility/DataGridTableToolbar";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
import { fetchAPI } from '@/utility/NetworkUtils';

const AllInventoryLogs = (props) => {
  const {inventory_logs} = props
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0,
  });

  useEffect(() => {
    loadData(page)
  },[page, paginationModel])

  const loadData = (page) => {
    setLoading(true)
    fetchAPI(
      `/warehouse/inventory_logs/table`,
      (json) => {
        if (!json.errors) {
          setRows(json.logs)
          setRowCount(json.count)
          setLoading(false)
        } else {
          console.log({
            errors: json.errors
          })
        }
      },
      {
        method: 'POST',
        body: {
          page: paginationModel.page,
          pageSize: paginationModel.pageSize
        }
      }
    )
  }

  const getColumns = () => {
    return [
      {field: "id", valueGetter: (value, item) => {return item?.item?.name}, flex: 1, headerName: "Item Name"},
      {field: "count", flex: 1, headerName: "Δ"},
      {field: "reason", flex: 1, headerName: "Reason"},
      {field: "notes", flex: 1, headerName: "Notes"},
      {field: "user", flex: 1, headerName: "User", renderCell: (params) => (params.value ? params.value.first_name + " " + params.value.last_name : "")},
      {field: "created_at", flex: 1, headerName: "Date", renderCell: (params) => (params.value ? new Date(params.value).toLocaleString() : "")}
    ]
  }

  return (
    <WarehouseLayout className="warehouse-item-index">
      <h1>Inventory Logs</h1>
      <div className="row">
        <div className="col-xs-12">
          <div className="warehouse-card">
            <DataGridPremium

              loading={loading}

              pagination
              paginationMode="server"
              onPageChange={(newPage) => console.log("===newPage===",newPage)}
              paginationModel={paginationModel}
              onPaginationModelChange={(params)=> {
                setPaginationModel(params)
              }}
              rowCount={rowCount}

              autoHeight
              disableColumnSorting
              disableColumnFilter
              disableSearch={true}
              disableFilter
              unstable_headerFilters
              rows={rows}
              columns={getColumns()}
              // slots={{
              //   toolbar: DataGridTableToolbar
              // }}
              style={{
                cell: {
                  paddingBottom: 0,
                  paddingRight: 0,
                  whiteSpace: 'normal',
                  overflow: 'visible',
                },
                background: "white"
              }}
              pageSizeOptions={[100, 200, 500]}
            />
          </div>
        </div>
      </div>
    </WarehouseLayout>
  );
}

export default AllInventoryLogs